import actions from './routes/actions';
import configuration from './routes/configuration';
import configurations from './routes/configurations';
import taxonomies from './routes/taxonomies';
import taxonomy from './routes/taxonomy';
import topic from './routes/topic';
import topicSet from './routes/topicSet';
import topicSets from './routes/topicSets';
import topics from './routes/topics';
import user from './routes/user';
import users from './routes/users';
import workspace from './routes/workspace';

export default {
  actions,
  workspace,
  configuration,
  configurations,
  taxonomy,
  taxonomies,
  topic,
  topics,
  topicSet,
  topicSets,
  user,
  users
};
