import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type {
  UserChangePasswordRequest,
  UserFindOneResponse,
  UserLoginRequest,
  UserLoginResponse,
  UserLoginWithOtcRequest,
  UserLoginWithOtcResponse,
  UserRegisterRequest,
  UserRemoveRequest,
  UserResetPasswordRequest,
  UserResetPasswordResponse,
  UserUpdateRequest,
  UserUpdateResponse
} from '../../schema';

import invite from './invite';
import invites from './invites';
import workspace from './workspace';
import workspaces from './workspaces';

const path = 'user';

export default {
  findOne: async (uuid: string): ApiPromise<UserFindOneResponse> => {
    return await apiCall('GET', `${path}/${uuid}`);
  },
  update: async (request: UserUpdateRequest): ApiPromise<UserUpdateResponse> => {
    return await apiCall('PUT', path, request);
  },
  remove: async (request: UserRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  },
  login: async (request: UserLoginRequest): ApiPromise<UserLoginResponse> => {
    return await apiCall('POST', `${path}/login`, request, { withErrorMessageBox: false });
  },
  loginWithOtc: async (request: UserLoginWithOtcRequest): ApiPromise<UserLoginWithOtcResponse> => {
    return await apiCall('POST', `${path}/login/otc`, request, { withErrorMessageBox: false });
  },
  logout: async (): ApiPromise<void> => {
    return await apiCall('POST', `${path}/logout`, undefined, { withErrorMessageBox: false });
  },
  changePassword: async (request: UserChangePasswordRequest): ApiPromise<void> => {
    return await apiCall('POST', `${path}/change-password`, request);
  },
  resetPassword: async (
    request: UserResetPasswordRequest
  ): ApiPromise<UserResetPasswordResponse> => {
    return await apiCall('POST', `${path}/reset-password`, request);
  },
  register: async (request: UserRegisterRequest): ApiPromise<void> => {
    return await apiCall('POST', `${path}/register`, request);
  },
  invite,
  invites,
  workspace,
  workspaces
};
