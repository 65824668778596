import { defineStore } from 'pinia';

import { getStoreName } from '../helpers/utility';

type MainLoaderReason = 'workspace-changing';

export type LoaderState = {
  /**
   * For what reasons are a main = full page load happening?
   *
   * We prefer this rather than a simple boolean as it documents what's asking for the load and why.
   *
   * fib-respond has like 3 attributes called 'loading' that random components set for random
   * reasons that aren't really traceable and very hard to debug as a boolean is just true or false.
   */
  mainLoaderReasons: MainLoaderReason[];
};

export const useLoaderStore = defineStore(getStoreName('loader'), {
  persist: false,
  state: (): LoaderState => ({
    mainLoaderReasons: []
  }),
  getters: {
    hasMainLoader: state => !!state.mainLoaderReasons.length
  },
  actions: {
    async withFullPageLoader<Result>(reason: MainLoaderReason, operation: () => Promise<Result>) {
      this.mainLoaderReasons.push(reason);

      try {
        return await operation();
      } finally {
        this.mainLoaderReasons = this.mainLoaderReasons.filter(r => r !== reason);
      }
    }
  }
});
