import type { ApiCallOptions, ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type {
  UserInviteAcceptRequest,
  UserInviteFindOneResponse,
  UserInviteRemoveRequest
} from '../../schema';

const path = 'user/invite';

export default {
  findOne: async (
    uuid: string,
    options?: ApiCallOptions
  ): ApiPromise<UserInviteFindOneResponse> => {
    return await apiCall('GET', `${path}/${uuid}`, undefined, options);
  },
  accept: async (request: UserInviteAcceptRequest): ApiPromise<void> => {
    return await apiCall('POST', `${path}/accept`, request);
  },
  remove: async (request: UserInviteRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  }
};
