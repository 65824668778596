import type { ApiCallOptions, ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type {
  UserWorkspaceRemoveRequest,
  UserWorkspaceUpdateRequest,
  UserWorkspaceUseRequest,
  UserWorkspaceUseResponse
} from '../../schema';

const path = 'user/workspace';

export default {
  update: async (request: UserWorkspaceUpdateRequest): ApiPromise<void> => {
    return await apiCall('PUT', path, request);
  },
  remove: async (request: UserWorkspaceRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  },
  use: async (
    request: UserWorkspaceUseRequest,
    options?: ApiCallOptions
  ): ApiPromise<UserWorkspaceUseResponse> => {
    return await apiCall('POST', `${path}/use`, request, options);
  }
};
