import { defineStore } from 'pinia';

import managementApi from '../api/managementApi';
import type {
  TaxonomiesFindAllRequest,
  TaxonomiesFindAllResponse,
  TaxonomyCreateRequest,
  TaxonomyFindOneResponse,
  TaxonomyUpdateRequest
} from '../api/managementApi/schema';
import { removeRowByUuid, updateRow } from '../helpers/rows';
import type { ExtractSortFields } from '../helpers/utility';
import { getStoreName } from '../helpers/utility';

export type DetailTaxonomy = TaxonomyFindOneResponse;
export type ListingTaxonomy = TaxonomiesFindAllResponse['rows'][number];
export type TaxonomiesSortableFields = ExtractSortFields<TaxonomiesFindAllRequest>;

export type TaxonomiesState = {
  taxonomies: ListingTaxonomy[];
};

export const useTaxonomiesStore = defineStore(getStoreName('taxonomies'), {
  // We don't persist this as there's no need to & to allow the current selection to differ if the
  // user has different configurations open in different browser tabs.
  persist: false,
  pagination: true,
  state: (): TaxonomiesState => ({
    taxonomies: []
  }),
  actions: {
    async findAll(reset: boolean, options?: TaxonomiesFindAllRequest) {
      await this.$paginate({
        items: this.taxonomies,
        type: 'offset',
        limit: 50,
        reset,
        request: async pagination => {
          return (await managementApi.taxonomies.findAll({ ...pagination, ...options })).data?.rows;
        }
      });
    },
    async findOne(uuid: string) {
      return (await managementApi.taxonomy.findOne(uuid)).data;
    },
    async create(create: TaxonomyCreateRequest) {
      return await managementApi.taxonomy.create(create);
    },
    async update(update: TaxonomyUpdateRequest) {
      const result = await managementApi.taxonomy.update(update);

      if (result.errored) {
        return false;
      }

      updateRow(this.taxonomies, update);

      return true;
    },
    async remove(uuid: string) {
      const result = await managementApi.taxonomy.remove({ uuid });

      if (result.errored) {
        return false;
      }

      removeRowByUuid(this.taxonomies, uuid);
      return true;
    }
  }
});
