import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { UserRoleId } from '../helpers/const';
import { USER_ROLE } from '../helpers/const';
import { useUserStore } from '../store/user';

type UseUserRuleReturn = {
  /** The current user's role id. */
  roleId: ComputedRef<UserRoleId | undefined>;
  /** Has the logged in user been granted permissions below or including that of a reader? */
  isReaderDown: ComputedRef<boolean>;
  /** Has the logged in user been granted permissions below or including that of a member? */
  isMemberDown: ComputedRef<boolean>;
  /** Does the current user have a role within the provided set. */
  isRole: (roles: UserRoleId[]) => boolean;
};

export const useUserRole = (): UseUserRuleReturn => {
  // ** Data **
  const userStore = useUserStore();

  // ** Computed **
  const roleId = computed<UserRoleId | undefined>(
    () => userStore.currentUser?.role.id as UserRoleId | undefined
  );

  const isReaderDown = computed<boolean>(() => isRole([USER_ROLE.READER]));
  const isMemberDown = computed<boolean>(() => isRole([USER_ROLE.MEMBER, USER_ROLE.READER]));

  // ** Methods **
  const isRole = (roles: UserRoleId[]): boolean => roles.includes(roleId.value as UserRoleId);

  return {
    roleId,
    isReaderDown,
    isMemberDown,
    isRole
  };
};
