import type { ApiPromise } from '../../utility';
import { apiCall } from '../client';
import type { TopicsFindAllRequest, TopicsFindAllResponse } from '../schema';

const path = 'topics';

export default {
  findAll: async (request: TopicsFindAllRequest): ApiPromise<TopicsFindAllResponse> => {
    return await apiCall('POST', path, request);
  }
};
