import type { ApiPromise } from '../../utility';
import { apiCall } from '../client';
import type { ConfigurationsFindAllRequest, ConfigurationsFindAllResponse } from '../schema';

const path = 'configurations';

export default {
  findAll: async (
    request: ConfigurationsFindAllRequest
  ): ApiPromise<ConfigurationsFindAllResponse> => {
    return await apiCall('POST', path, request);
  }
};
