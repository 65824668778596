import type { ApiPromise } from '../../utility';
import { apiCall } from '../client';
import type {
  TopicCreateRequest,
  TopicCreateResponse,
  TopicFindOneResponse,
  TopicRemoveRequest,
  TopicUpdateRequest
} from '../schema';

const path = 'topic';

export default {
  findOne: async (uuid: string): ApiPromise<TopicFindOneResponse> => {
    return await apiCall('GET', `${path}/${uuid}`);
  },
  create: async (request: TopicCreateRequest): ApiPromise<TopicCreateResponse> => {
    return await apiCall('POST', path, request);
  },
  update: async (request: TopicUpdateRequest): ApiPromise<void> => {
    return await apiCall('PUT', path, request);
  },
  remove: async (request: TopicRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  }
};
