import type { ValueOf } from '@feeditback/fib-components';

export const NAV_HEIGHT = 45;
export const SIDEBAR_WIDTH = 300;
export const NAVIGATION_DELAY = 2000;

/** Names of all routes provided to vue-router. */
export const ROUTE_NAME = {
  DEFAULT: 'default',
  ERROR_404: 'error-404',
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password',
  CHANGE_PASSWORD: 'change-password',
  REGISTER: 'register',
  SETTINGS: 'settings',
  AUDIT_LOG: 'audit-log',
  TOPICS: 'topics',
  TOPIC_SETS: 'topic-sets',
  CONFIGURATIONS: 'configurations',
  CONFIGURATION: 'configuration',
  TAXONOMIES: 'taxonomies',
  ASSIGNED_TOPICS: 'assigned-topics',
  SENTIMENTS: 'sentiments',
  DOCUMENT_TESTING: 'document-testing',
  USERS: 'users'
} as const;

export type RouteName = ValueOf<typeof ROUTE_NAME>;

export const SHARED_MODAL = {
  CONFIGURATIONS: 'MantleConfigurationsDetailModal',
  TOPIC_SETS: 'MantleTopicSetsDetailModal',
  TOPICS: 'MantleTopicsDetailModal',
  USERS: 'MantleUsersDetailModal',
  TAXONOMIES: 'MantleTaxonomiesDetailModal',
  SENTIMENTS: 'MantleSentimentsDetailModal'
} as const;

export type SharedModalName = ValueOf<typeof SHARED_MODAL>;

export const USER_ROLE = {
  ADMIN: 1,
  MEMBER: 2,
  READER: 3
} as const;

export type UserRoleId = ValueOf<typeof USER_ROLE>;

export const USER_ROLE_TEXT_KEY = {
  [USER_ROLE.ADMIN]: 'admin',
  [USER_ROLE.MEMBER]: 'member',
  [USER_ROLE.READER]: 'reader'
} as const satisfies Record<UserRoleId, string>;
