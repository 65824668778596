import type { ApiPromise } from '../../utility';
import { apiCall } from '../client';
import type { TaxonomiesFindAllRequest, TaxonomiesFindAllResponse } from '../schema';

const path = 'taxonomies';

export default {
  findAll: async (request: TaxonomiesFindAllRequest): ApiPromise<TaxonomiesFindAllResponse> => {
    return await apiCall('POST', path, request);
  }
};
