import { defineStore } from 'pinia';

import managementApi from '../api/managementApi';
import type {
  ConfigurationSentimentCreateRequest,
  ConfigurationSentimentFindOneResponse,
  ConfigurationSentimentsFindAllRequest,
  ConfigurationSentimentsFindAllResponse,
  ConfigurationSentimentUpdateRequest
} from '../api/managementApi/schema';
import { removeRowByUuid, updateRow } from '../helpers/rows';
import type { ExtractSortFields } from '../helpers/utility';
import { getStoreName } from '../helpers/utility';

export type DetailSentiment = ConfigurationSentimentFindOneResponse;
export type ListingSentiment = ConfigurationSentimentsFindAllResponse['rows'][number];
export type ConfigurationSentimentFields = ExtractSortFields<ConfigurationSentimentsFindAllRequest>;

export type SentimentsState = {
  sentiments: ListingSentiment[];
};

export const useSentimentsStore = defineStore(getStoreName('sentiments'), {
  // We don't persist this as there's no need to & to allow the current selection to differ if the
  // user has different configurations open in different browser tabs.
  persist: false,
  pagination: true,
  state: (): SentimentsState => ({
    sentiments: []
  }),
  actions: {
    async findAll(
      reset: boolean,
      configId: number,
      options?: Omit<ConfigurationSentimentsFindAllRequest, 'config_id'>
    ) {
      await this.$paginate({
        items: this.sentiments,
        type: 'offset',
        limit: 50,
        reset,
        request: async pagination => {
          return (
            await managementApi.configuration.sentiments.findAll({
              config_id: configId,
              ...pagination,
              ...options
            })
          ).data?.rows;
        }
      });
    },
    async findOne(uuid: string) {
      return (await managementApi.configuration.sentiment.findOne(uuid)).data;
    },
    async create(create: ConfigurationSentimentCreateRequest) {
      return await managementApi.configuration.sentiment.create(create);
    },
    async update(update: ConfigurationSentimentUpdateRequest) {
      const result = await managementApi.configuration.sentiment.update(update);

      if (result.errored) {
        return false;
      }

      updateRow(this.sentiments, update);

      return true;
    },
    async remove(uuid: string) {
      const result = await managementApi.configuration.sentiment.remove({ uuid });

      if (result.errored) {
        return false;
      }

      removeRowByUuid(this.sentiments, uuid);
      return true;
    }
  }
});
