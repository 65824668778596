import type { AxiosError } from 'axios';
import axios, { isAxiosError } from 'axios';

import { ROUTE_NAME } from '../../helpers/const';
import { useRouter } from '../../router';
import { useUserStore } from '../../store/user';
import { createApiClient } from '../utility';

import type { ErrorResponse } from './schema';

// Mantle Management API specific functionality

const client = axios.create();
client.defaults.baseURL = `${import.meta.env.VITE_MANTLE_MANAGEMENT_API_URL}api`;

const onError = async (error: unknown): Promise<unknown> => {
  if (isAxiosError(error)) {
    // If we've become de-authenticated, then redirect to login immediately, and don't bother with
    // anything else.
    if ([401].includes(error.response?.status || -1)) {
      await useUserStore().logout(false);

      const router = useRouter();
      if (!router.currentRoute.value.meta.noRedirectOnAuthError) {
        await router.push({ name: ROUTE_NAME.LOGIN });
      }

      // Ensure that no error message box is shown in this scenario.
      if (error.config?.options) {
        error.config.options.withErrorMessageBox = false;
      }
    }
  }

  return Promise.reject(error);
};

client.interceptors.request.use(config => {
  const userStore = useUserStore();
  // Add the Mantle API auth token by default
  if (config.options?.withAccessToken ?? true) {
    config.headers['x-mantle-auth-token'] = userStore.currentUser?.tokens[0]?.token;
  }

  // Always attempt to pass a workspace uuid if we have one
  const workspaceUuid = userStore.currentWorkspace?.uuid;
  if (workspaceUuid) {
    config.headers['x-mantle-workspace-uuid'] = workspaceUuid;
  }

  return config;
}, onError);

client.interceptors.response.use(response => response, onError);

export const apiCall = createApiClient(
  client,
  e => (e as AxiosError<ErrorResponse>).response?.data?.error?.message
);
