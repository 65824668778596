import type { Component } from 'vue';

import type { CustomRouteRecordRaw } from '..';
import { ROUTE_NAME } from '../../helpers/const';

export default [
  {
    path: '/settings',
    name: ROUTE_NAME.SETTINGS,
    component: (): Promise<Component> => import('../../views/MantleSettings.vue'),
    meta: { intrinsicHeight: true }
  },
  {
    path: '/audit-log',
    name: ROUTE_NAME.AUDIT_LOG,
    component: (): Promise<Component> => import('../../views/MantleAuditLog.vue')
  },
  {
    path: '/topics',
    name: ROUTE_NAME.TOPICS,
    component: (): Promise<Component> => import('../../views/MantleTopics.vue')
  },
  {
    path: '/topic-sets',
    name: ROUTE_NAME.TOPIC_SETS,
    component: (): Promise<Component> => import('../../views/MantleTopicSets.vue')
  },
  {
    path: '/taxonomies',
    name: ROUTE_NAME.TAXONOMIES,
    component: (): Promise<Component> => import('../../views/MantleTaxonomies.vue')
  },
  {
    path: '/configurations',
    name: ROUTE_NAME.CONFIGURATIONS,
    component: (): Promise<Component> => import('../../views/MantleConfigurations.vue')
  },
  {
    path: '/configuration',
    name: ROUTE_NAME.CONFIGURATION,
    redirect: { name: ROUTE_NAME.ASSIGNED_TOPICS },
    component: (): Promise<Component> => import('../../views/MantleConfiguration.vue'),
    children: [
      {
        path: 'assigned-topics',
        name: ROUTE_NAME.ASSIGNED_TOPICS,
        component: (): Promise<Component> =>
          import('../../views/configurations/MantleAssignedTopics.vue')
      },
      {
        path: 'sentiments',
        name: ROUTE_NAME.SENTIMENTS,
        component: (): Promise<Component> =>
          import('../../views/configurations/MantleSentiments.vue')
      },
      {
        path: 'document-testing',
        name: ROUTE_NAME.DOCUMENT_TESTING,
        component: (): Promise<Component> =>
          import('../../views/configurations/MantleDocumentTesting.vue'),
        meta: { intrinsicHeight: true }
      }
    ]
  },
  {
    path: '/users',
    name: ROUTE_NAME.USERS,
    component: (): Promise<Component> => import('../../views/MantleUsers.vue')
  }
] satisfies CustomRouteRecordRaw[];
