import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type { TopicSetTopicCreateRequest, TopicSetTopicRemoveRequest } from '../../schema';

const path = 'topic-set/topic';

export default {
  create: async (request: TopicSetTopicCreateRequest): ApiPromise<void> => {
    return await apiCall('POST', path, request);
  },
  remove: async (request: TopicSetTopicRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  }
};
