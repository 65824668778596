import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type { UsersInviteCreateRequest, UsersInviteCreateResponse } from '../../schema';

const path = 'users/invite';

export default {
  create: async (request: UsersInviteCreateRequest): ApiPromise<UsersInviteCreateResponse> => {
    return await apiCall('POST', path, request);
  }
};
