import { defineStore } from 'pinia';

import managementApi from '../api/managementApi';
import type {
  TopicCreateRequest,
  TopicFindOneResponse,
  TopicsFindAllRequest,
  TopicsFindAllResponse,
  TopicUpdateRequest
} from '../api/managementApi/schema';
import { removeRowByUuid, updateRow } from '../helpers/rows';
import type { ExtractSortFields } from '../helpers/utility';
import { getStoreName } from '../helpers/utility';

export type DetailTopic = TopicFindOneResponse;
export type ListingTopic = TopicsFindAllResponse['rows'][number];
export type TopicSortableFields = ExtractSortFields<TopicsFindAllRequest>;

export type TopicsState = {
  topics: ListingTopic[];
};

export const useTopicsStore = defineStore(getStoreName('topics'), {
  // We don't persist this as there's no need to & to allow the current selection to differ if the
  // user has different configurations open in different browser tabs.
  persist: false,
  pagination: true,
  state: (): TopicsState => ({
    topics: []
  }),
  actions: {
    async findAll(reset: boolean, options?: TopicsFindAllRequest) {
      await this.$paginate({
        items: this.topics,
        type: 'offset',
        limit: 50,
        reset,
        request: async pagination => {
          return (await managementApi.topics.findAll({ ...pagination, ...options })).data?.rows;
        }
      });
    },
    async findOne(uuid: string) {
      return (await managementApi.topic.findOne(uuid)).data;
    },
    async create(create: TopicCreateRequest) {
      return await managementApi.topic.create(create);
    },
    async update(update: TopicUpdateRequest) {
      const result = await managementApi.topic.update(update);

      if (result.errored) {
        return false;
      }

      updateRow(this.topics, update);

      return true;
    },
    async remove(uuid: string) {
      const result = await managementApi.topic.remove({ uuid });

      if (result.errored) {
        return false;
      }

      removeRowByUuid(this.topics, uuid);
      return true;
    },
    async updateTaxonomy(
      topicId: number,
      removedTaxonomyId: number | undefined,
      addedTaxonomyId: number | undefined
    ) {
      // Remove the old taxonomy id if there was one.
      if (removedTaxonomyId) {
        const response = await managementApi.taxonomy.topic.remove({
          topic_ids: [topicId],
          taxonomy_id: removedTaxonomyId
        });

        if (response.errored) {
          return false;
        }
      }

      // Set the new taxonomy id if there is one.
      if (addedTaxonomyId) {
        const response = await managementApi.taxonomy.topic.create({
          topic_ids: [topicId],
          taxonomy_id: addedTaxonomyId
        });

        if (response.errored) {
          return false;
        }
      }

      return true;
    }
  }
});
