import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type {
  ConfigurationCreateRequest,
  ConfigurationFindOneResponse,
  ConfigurationRemoveRequest,
  ConfigurationUpdateRequest
} from '../../schema';

import sentiment from './sentiment';
import sentiments from './sentiments';
import topic from './topic';
import topicSet from './topicSet';

const path = 'configuration';

export default {
  findOne: async (uuid: string): ApiPromise<ConfigurationFindOneResponse> => {
    return await apiCall('GET', `${path}/${uuid}`);
  },
  create: async (request: ConfigurationCreateRequest): ApiPromise<void> => {
    return await apiCall('POST', path, request);
  },
  update: async (request: ConfigurationUpdateRequest): ApiPromise<void> => {
    return await apiCall('PUT', path, request);
  },
  remove: async (request: ConfigurationRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  },
  sentiment,
  sentiments,
  topic,
  topicSet
};
