import { defineStore } from 'pinia';

import managementApi from '../api/managementApi';
import type { UserInvitesFindAllResponse } from '../api/managementApi/schema';
import { getStoreName } from '../helpers/utility';

export type ListingInvite = UserInvitesFindAllResponse['rows'][number];

export type InvitesState = {
  invites: ListingInvite[];
};

export const useInvitesStore = defineStore(getStoreName('invites'), {
  persist: true,
  pagination: true,
  state: (): InvitesState => ({
    invites: []
  }),
  actions: {
    async findAll(reset: boolean) {
      await this.$paginate({
        items: this.invites,
        type: 'offset',
        limit: 50,
        reset,
        request: async pagination => {
          return (await managementApi.user.invites.findAll(pagination)).data?.rows;
        }
      });
    }
  }
});
