import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type { UsersFindAllRequest, UsersFindAllResponse } from '../../schema';

import invite from './invite';

const path = 'users';

export default {
  findAll: async (request: UsersFindAllRequest): ApiPromise<UsersFindAllResponse> => {
    return await apiCall('POST', path, request);
  },
  invite
};
