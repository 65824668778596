import type { ApiPromise } from '../../utility';
import { apiCall } from '../client';
import type { TopicSetsFindAllRequest, TopicSetsFindAllResponse } from '../schema';

const path = 'topic-sets';

export default {
  findAll: async (request: TopicSetsFindAllRequest): ApiPromise<TopicSetsFindAllResponse> => {
    return await apiCall('POST', path, request);
  }
};
