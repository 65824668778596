import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type {
  ConfigurationTopicSetCreateRequest,
  ConfigurationTopicSetRemoveRequest
} from '../../schema';

const path = 'configuration/topic-set';

export default {
  create: async (request: ConfigurationTopicSetCreateRequest): ApiPromise<void> => {
    return await apiCall('POST', path, request);
  },
  remove: async (request: ConfigurationTopicSetRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  }
};
