import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type { TaxonomyTopicCreateRequest, TaxonomyTopicRemoveRequest } from '../../schema';

const path = 'taxonomy/topic';

export default {
  create: async (request: TaxonomyTopicCreateRequest): ApiPromise<void> => {
    return await apiCall('POST', path, request);
  },
  remove: async (request: TaxonomyTopicRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  }
};
