import { defineStore } from 'pinia';

import managementApi from '../api/managementApi';
import type {
  ConfigurationCreateRequest,
  ConfigurationFindOneResponse,
  ConfigurationsFindAllRequest,
  ConfigurationsFindAllResponse,
  ConfigurationUpdateRequest
} from '../api/managementApi/schema';
import { removeRowByUuid, updateRow } from '../helpers/rows';
import type { ExtractSortFields } from '../helpers/utility';
import { getStoreName } from '../helpers/utility';

import { useUserStore } from './user';

export type DetailConfiguration = ConfigurationFindOneResponse;
export type ListingConfiguration = ConfigurationsFindAllResponse['rows'][number];
export type ConfigurationSortableFields = ExtractSortFields<ConfigurationsFindAllRequest>;

export type ConfigurationsState = {
  configurations: ListingConfiguration[];
};

export const useConfigurationsStore = defineStore(getStoreName('configurations'), {
  // We don't persist this as there's no need to & to allow the current selection to differ if the
  // user has different configurations open in different browser tabs.
  persist: false,
  pagination: true,
  state: (): ConfigurationsState => ({
    configurations: []
  }),
  actions: {
    async findAll(reset: boolean, options: ConfigurationsFindAllRequest) {
      await this.$paginate({
        items: this.configurations,
        type: 'offset',
        limit: 50,
        reset,
        request: async pagination => {
          return (await managementApi.configurations.findAll({ ...pagination, ...options })).data
            ?.rows;
        }
      });
    },
    async findOne(uuid: string) {
      return (await managementApi.configuration.findOne(uuid)).data;
    },
    async create(create: ConfigurationCreateRequest) {
      return await managementApi.configuration.create(create);
    },
    async update(update: ConfigurationUpdateRequest) {
      const result = await managementApi.configuration.update(update);

      if (result.errored) {
        return false;
      }

      updateRow(this.configurations, update);

      // Update our selected configuration if it's just been removed.
      const userStore = useUserStore();
      if (userStore.currentConfiguration?.uuid === update.uuid) {
        userStore.currentConfiguration = {
          ...userStore.currentConfiguration,
          ...(update as DetailConfiguration)
        };
      }

      return true;
    },
    async remove(uuid: string) {
      const result = await managementApi.configuration.remove({ uuid });

      if (result.errored) {
        return false;
      }

      removeRowByUuid(this.configurations, uuid);

      // Deselect our selected configuration if it's just been removed.
      const userStore = useUserStore();
      if (userStore.currentConfiguration?.uuid === uuid) {
        userStore.currentConfiguration = undefined;
      }

      return true;
    }
  }
});
