import { defineStore } from 'pinia';

import managementApi from '../api/managementApi';
import type {
  TopicSetCreateRequest,
  TopicSetFindOneResponse,
  TopicSetsFindAllRequest,
  TopicSetsFindAllResponse,
  TopicSetUpdateRequest
} from '../api/managementApi/schema';
import { removeRowByUuid, updateRow } from '../helpers/rows';
import { getStoreName } from '../helpers/utility';

export type DetailTopicSet = TopicSetFindOneResponse;
export type ListingTopicSet = TopicSetsFindAllResponse['rows'][number];

export type TopicSetsState = {
  topicSets: ListingTopicSet[];
};

export const useTopicSetsStore = defineStore(getStoreName('topic-sets'), {
  // We don't persist this as there's no need to & to allow the current selection to differ if the
  // user has different configurations open in different browser tabs.
  persist: false,
  pagination: true,
  state: (): TopicSetsState => ({
    topicSets: []
  }),
  actions: {
    async findAll(reset: boolean, options?: TopicSetsFindAllRequest) {
      await this.$paginate({
        items: this.topicSets,
        type: 'offset',
        limit: 50,
        reset,
        request: async pagination => {
          return (await managementApi.topicSets.findAll({ ...pagination, ...options })).data?.rows;
        }
      });
    },
    async findOne(uuid: string) {
      return (await managementApi.topicSet.findOne(uuid)).data;
    },
    async create(create: TopicSetCreateRequest) {
      return await managementApi.topicSet.create(create);
    },
    async update(update: TopicSetUpdateRequest) {
      const result = await managementApi.topicSet.update(update);

      if (result.errored) {
        return false;
      }

      updateRow(this.topicSets, update);

      return true;
    },
    async remove(uuid: string) {
      const result = await managementApi.topicSet.remove({ uuid });

      if (result.errored) {
        return false;
      }

      removeRowByUuid(this.topicSets, uuid);
      return true;
    }
  }
});
