import { t } from '@feeditback/fib-components';
import type { Component } from 'vue';
import type { NavigationGuardWithThis, Router, RouteRecordName, RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHashHistory } from 'vue-router';

import { ROUTE_NAME } from '../helpers/const';
import { useUserStore } from '../store/user';
import { useNavigation } from '../use/useNavigation';
import { useRouteTitle } from '../use/useRouteTitle';

import auth from './routes/auth';
import main from './routes/main';

// Enforce properties required on route meta
declare module 'vue-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface RouteMeta {
    /** Should this page be available even if not logged in - defaults to false. */
    isPublic?: boolean;
    /** If true, don't redirect to login if there is an authentication error - defaults to false. */
    noRedirectOnAuthError?: boolean;
    /** Instruct the layout to use intrinsic height sizing. */
    intrinsicHeight?: boolean;
  }
}

// Force people to name their routes
export type CustomRouteRecordRaw = RouteRecordRaw & { name: RouteRecordName };

export const routes: CustomRouteRecordRaw[] = [
  // Default route (you'll be redirected to login if you're not already there)
  {
    path: '/',
    name: ROUTE_NAME.DEFAULT,
    redirect: '/configurations'
  },
  ...auth,
  ...main,
  {
    path: '/:pathMatch(.*)*',
    name: ROUTE_NAME.ERROR_404,
    component: (): Component => import('../views/MantleError404.vue'),
    meta: {
      isPublic: true
    }
  }
];

const router = createRouter({
  // We're using Hash Mode as it doesn't require any server-side configuration - we could swap to
  // HTML5 Mode via createWebHistory(process.env.BASE_URL) - but that would mean asking the devops
  // people to set things up...
  history: createWebHashHistory(),
  routes
});

type BeforeEach = NavigationGuardWithThis<undefined>;

export const beforeEachRoute = (to: Parameters<BeforeEach>[0]): ReturnType<BeforeEach> => {
  if (document) {
    document.title = t('routes.title-template', {
      partialTitle: useRouteTitle(to.name?.toString() || '')
    });
  }

  if (!to.meta.isPublic) {
    const userStore = useUserStore();

    // Only allow de-authenticated access to routes marked public.
    if (!userStore.isLoggedIn) {
      return { name: ROUTE_NAME.LOGIN };
    }

    // If the user is tagged as requiring a password change, don't let them access any authenticated
    // route from that page until they've done it.
    if (to.name !== ROUTE_NAME.CHANGE_PASSWORD && userStore.resettingPassword) {
      return userStore.getChangePasswordRouteLocation();
    }

    // If the route is one that the user can normally navigate to, but it's been disabled - that
    // will be due to user permissions - so prevent such a navigation.
    const { isDisabledNavigableRoute } = useNavigation(to);
    if (isDisabledNavigableRoute.value) {
      return { name: ROUTE_NAME.DEFAULT };
    }
  }
};

router.beforeEach(beforeEachRoute);

// It's better to export a function here rather than the global router variable for unit testing
// purposes as it means to mock this module we don't need a router instance to exist before
// beforeEach is called.
export const useRouter = (): Router => router;
