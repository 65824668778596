import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type { UserWorkspacesFindAllRequest, UserWorkspacesFindAllResponse } from '../../schema';

const path = 'user/workspaces';

export default {
  findAll: async (
    request: UserWorkspacesFindAllRequest
  ): ApiPromise<UserWorkspacesFindAllResponse> => {
    return await apiCall('POST', path, request);
  }
};
