import { t } from '@feeditback/fib-components';

import { useUserStore } from '../store/user';

export const useRouteTitle = (routeName: string): string => {
  const userStore = useUserStore();

  return t(`routes.${routeName}.title`, {
    currentWorkspaceName: userStore.currentWorkspace?.name || '',
    currentConfigurationName:
      userStore.currentConfiguration?.name || t('routes.configuration.title-fallback')
  });
};
