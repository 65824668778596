export const updateRow = <Row, Key extends keyof Row>(
  rows: Row[],
  update: Partial<Row>,
  key?: Key
): void => {
  const rowKey = (key ?? 'uuid') as Key;
  const index = rows.findIndex(i => i[rowKey] === update[rowKey]);
  if (index >= 0) {
    rows[index] = { ...(rows[index] as Row), ...update };
  }
};

type Uuid = string | number;

export const removeRowByUuid = (rows: { uuid: Uuid }[], uuid: Uuid): void => {
  const index = rows.findIndex(i => i.uuid === uuid);
  if (index >= 0) {
    rows.splice(index, 1);
  }
};
