import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type {
  TaxonomyCreateRequest,
  TaxonomyFindOneResponse,
  TaxonomyRemoveRequest,
  TaxonomyUpdateRequest
} from '../../schema';

import topic from './topic';

const path = 'taxonomy';

export default {
  findOne: async (uuid: string): ApiPromise<TaxonomyFindOneResponse> => {
    return await apiCall('GET', `${path}/${uuid}`);
  },
  create: async (request: TaxonomyCreateRequest): ApiPromise<void> => {
    return await apiCall('POST', path, request);
  },
  update: async (request: TaxonomyUpdateRequest): ApiPromise<void> => {
    return await apiCall('PUT', path, request);
  },
  remove: async (request: TaxonomyRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  },
  topic
};
