import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type {
  TopicSetCreateRequest,
  TopicSetFindOneResponse,
  TopicSetRemoveRequest,
  TopicSetUpdateRequest
} from '../../schema';

import topic from './topic';

const path = 'topic-set';

export default {
  findOne: async (uuid: string): ApiPromise<TopicSetFindOneResponse> => {
    return await apiCall('GET', `${path}/${uuid}`);
  },
  create: async (request: TopicSetCreateRequest): ApiPromise<void> => {
    return await apiCall('POST', path, request);
  },
  update: async (request: TopicSetUpdateRequest): ApiPromise<void> => {
    return await apiCall('PUT', path, request);
  },
  remove: async (request: TopicSetRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  },
  topic
};
