import type { NavigationNode, UseNavigationReturn } from '@feeditback/fib-components';
import { useNavigation as useNavigationBuilder } from '@feeditback/fib-components';
import { computed } from 'vue';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

import auditLog from '../assets/icons/audit-log.svg';
import configurations from '../assets/icons/configurations.svg';
import documentTesting from '../assets/icons/document-testing.svg';
import sentiments from '../assets/icons/sentiments.svg';
import settings from '../assets/icons/settings.svg';
import taxonomies from '../assets/icons/taxonomies.svg';
import topicSets from '../assets/icons/topic-sets.svg';
import topics from '../assets/icons/topics.svg';
import users from '../assets/icons/users.svg';

import type { RouteName } from '../helpers/const';
import { ROUTE_NAME } from '../helpers/const';
import { useUserStore } from '../store/user';

import { useRouteTitle } from './useRouteTitle';
import { useUserRole } from './useUserRole';

export const useNavigation = (
  route: RouteLocationNormalizedLoaded
): UseNavigationReturn<RouteName> => {
  // ** Data **
  const { isMemberDown } = useUserRole();
  const userStore = useUserStore();

  // ** Computed **
  const nodes = computed<NavigationNode<RouteName>[]>(() => [
    { name: ROUTE_NAME.SETTINGS, icon: settings },
    { name: ROUTE_NAME.CONFIGURATIONS, icon: configurations },
    { name: ROUTE_NAME.TOPIC_SETS, icon: topicSets },
    { name: ROUTE_NAME.TOPICS, icon: topics },
    { name: ROUTE_NAME.TAXONOMIES, icon: taxonomies },
    { name: ROUTE_NAME.USERS, icon: users, enabled: !isMemberDown.value },
    { name: ROUTE_NAME.AUDIT_LOG, icon: auditLog, enabled: !isMemberDown.value },
    {
      name: ROUTE_NAME.CONFIGURATION,
      selectable: false,
      enabled: !!userStore.currentConfiguration,
      help: userStore.currentConfiguration?.description,
      children: [
        { name: ROUTE_NAME.ASSIGNED_TOPICS, icon: topics },
        { name: ROUTE_NAME.SENTIMENTS, icon: sentiments },
        { name: ROUTE_NAME.DOCUMENT_TESTING, icon: documentTesting }
      ]
    }
  ]);

  return useNavigationBuilder({ route, nodes, resolveLabel: useRouteTitle });
};
