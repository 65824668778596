import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type { UserInvitesFindAllRequest, UserInvitesFindAllResponse } from '../../schema';

const path = 'user/invites';

export default {
  findAll: async (request: UserInvitesFindAllRequest): ApiPromise<UserInvitesFindAllResponse> => {
    return await apiCall('POST', path, request);
  }
};
