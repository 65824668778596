import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type {
  ConfigurationSentimentsFindAllRequest,
  ConfigurationSentimentsFindAllResponse
} from '../../schema';

const path = 'configuration/sentiments';

export default {
  findAll: async (
    request: ConfigurationSentimentsFindAllRequest
  ): ApiPromise<ConfigurationSentimentsFindAllResponse> => {
    return await apiCall('POST', path, request);
  }
};
