import type { Component } from 'vue';

import type { CustomRouteRecordRaw } from '..';
import { ROUTE_NAME } from '../../helpers/const';

export default [
  {
    path: '/login',
    name: ROUTE_NAME.LOGIN,
    component: (): Promise<Component> => import('../../views/auth/MantleLogin.vue'),
    meta: { isPublic: true, noRedirectOnAuthError: true }
  },
  {
    path: '/reset-password',
    name: ROUTE_NAME.RESET_PASSWORD,
    component: (): Promise<Component> => import('../../views/auth/MantleResetPassword.vue'),
    meta: { isPublic: true, noRedirectOnAuthError: true }
  },
  {
    path: '/change-password/:userUuid',
    name: ROUTE_NAME.CHANGE_PASSWORD,
    component: (): Promise<Component> => import('../../views/auth/MantleChangePassword.vue'),
    meta: { isPublic: false },
    props: true
  },
  {
    path: '/register/:inviteUuid',
    name: ROUTE_NAME.REGISTER,
    component: (): Promise<Component> => import('../../views/auth/MantleRegister.vue'),
    meta: { isPublic: true, noRedirectOnAuthError: true },
    props: true
  }
] satisfies CustomRouteRecordRaw[];
