import { defineStore } from 'pinia';

import managementApi from '../api/managementApi';
import type {
  UserFindOneResponse,
  UsersFindAllRequest,
  UsersFindAllResponse,
  UserUpdateRequest
} from '../api/managementApi/schema';
import type { UserRoleId } from '../helpers/const';
import { removeRowByUuid, updateRow } from '../helpers/rows';
import type { ExtractSortFields } from '../helpers/utility';
import { getStoreName } from '../helpers/utility';

import { useUserStore } from './user';

export type DetailUser = UserFindOneResponse;
export type ListingUser = UsersFindAllResponse['rows'][number];
export type UserSortableFields = ExtractSortFields<UsersFindAllRequest>;

export type UsersState = {
  users: ListingUser[];
};

export type UserUpdateWithRoleRequest = UserUpdateRequest & { role_id?: UserRoleId };

export const useUsersStore = defineStore(getStoreName('users'), {
  // We don't persist this as there's no need to & to allow the current selection to differ if the
  // user has different configurations open in different browser tabs.
  persist: false,
  pagination: true,
  state: (): UsersState => ({
    users: []
  }),
  actions: {
    async findAll(reset: boolean, options: UsersFindAllRequest) {
      await this.$paginate({
        items: this.users,
        type: 'offset',
        limit: 50,
        reset,
        request: async pagination => {
          return (await managementApi.users.findAll({ ...pagination, ...options })).data?.rows;
        }
      });
    },
    async findOne(uuid: string) {
      return (await managementApi.user.findOne(uuid)).data;
    },
    async update(update: UserUpdateWithRoleRequest) {
      const { role_id: roleId, ...userUpdate } = update;

      const userResult = await managementApi.user.update(userUpdate);
      if (userResult.errored) {
        return false;
      }

      // If role needs to be updated, that's a separate endpoint. We do this after making the main
      // update as if the user has changed their role away from admin, after this is done, calling
      // the above endpoint would fail.
      if (roleId) {
        const roleResult = await managementApi.user.workspace.update({
          user_uuid: update.uuid,
          role_id: roleId
        });

        if (roleResult.errored) {
          return false;
        }
      }

      // Compose the updated user.
      const updatedUser = {
        ...userResult.data,
        ...(roleId ? { role: { id: roleId, name: userResult.data.role.name } } : undefined)
      };

      updateRow(this.users, updatedUser);

      // If the user has just edited themselves, update the information stored in the user store.
      const userStore = useUserStore();
      if (updatedUser.uuid === userStore.currentUser?.uuid) {
        userStore.currentUser = { ...userStore.currentUser, ...updatedUser };
      }

      return true;
    },
    async removeFromWorkspace(uuid: string) {
      const result = await managementApi.user.workspace.remove({ user_uuid: uuid });

      if (result.errored) {
        return false;
      }

      removeRowByUuid(this.users, uuid);
      return true;
    }
  }
});
