import type { ApiPromise } from '../../../utility';
import { apiCall } from '../../client';
import type {
  ConfigurationSentimentCreateRequest,
  ConfigurationSentimentFindOneResponse,
  ConfigurationSentimentRemoveRequest,
  ConfigurationSentimentUpdateRequest
} from '../../schema';

const path = 'configuration/sentiment';

export default {
  findOne: async (uuid: string): ApiPromise<ConfigurationSentimentFindOneResponse> => {
    return await apiCall('GET', `${path}/${uuid}`);
  },
  create: async (request: ConfigurationSentimentCreateRequest): ApiPromise<void> => {
    return await apiCall('POST', path, request);
  },
  update: async (request: ConfigurationSentimentUpdateRequest): ApiPromise<void> => {
    return await apiCall('PUT', path, request);
  },
  remove: async (request: ConfigurationSentimentRemoveRequest): ApiPromise<void> => {
    return await apiCall('DELETE', path, request);
  }
};
