import type { ApiCallOptions, ApiPromise } from '../../utility';
import { apiCall } from '../client';
import type { WorkspaceFindOneResponse, WorkspaceUpdateRequest } from '../schema';

const path = 'workspace';

export default {
  findOne: async (uuid: string, options?: ApiCallOptions): ApiPromise<WorkspaceFindOneResponse> => {
    return await apiCall('GET', `${path}/${uuid}`, undefined, options);
  },
  update: async (request: WorkspaceUpdateRequest): ApiPromise<void> => {
    return await apiCall('PUT', path, request);
  }
};
