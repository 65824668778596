import { t } from '@feeditback/fib-components';
import { defineStore } from 'pinia';

import type { RouteLocationRaw } from 'vue-router';

import managementApi from '../api/managementApi';
import type {
  UserLoginResponse,
  UserWorkspacesFindAllResponse,
  WorkspaceFindOneResponse,
  WorkspaceUpdateRequest
} from '../api/managementApi/schema';
import type { ApiPromise } from '../api/utility';
import { ROUTE_NAME } from '../helpers/const';
import { getStoreName } from '../helpers/utility';
import { useRouter } from '../router';

import type { DetailConfiguration } from './configurations';
import { useConfigurationsStore } from './configurations';
import { useInvitesStore } from './invites';
import { useLoaderStore } from './loader';
import { useSentimentsStore } from './sentiments';
import { useTaxonomiesStore } from './taxonomies';
import { useTopicSetsStore } from './topicSets';
import { useTopicsStore } from './topics';
import { useUsersStore } from './users';

export type DetailWorkspace = WorkspaceFindOneResponse;

export type ListingUserWorkspace = UserWorkspacesFindAllResponse['rows'][number];

export type UserState = {
  currentUser: UserLoginResponse | undefined;
  currentWorkspace: DetailWorkspace | undefined;
  // We store this in this persisted store so that it's remembers if the tab is duplicated / restored.
  currentConfiguration: DetailConfiguration | undefined;
  resettingPassword: boolean;
  availableWorkspaces: ListingUserWorkspace[];
  documentTestingDocument: string;
  sidebarOpen: boolean;
};

export const useUserStore = defineStore(getStoreName('user'), {
  persist: true,
  state: (): UserState => ({
    currentUser: undefined,
    currentWorkspace: undefined,
    currentConfiguration: undefined,
    resettingPassword: false,
    availableWorkspaces: [],
    documentTestingDocument: '',
    sidebarOpen: false
  }),
  getters: {
    isLoggedIn: state => !!state.currentUser
  },
  actions: {
    async loginWithPassword(email: string, password: string): Promise<string | undefined> {
      return this._login(() =>
        managementApi.user.login({ email: email.trim(), password: password.trim() })
      );
    },
    async loginWithOneTimeCode(email: string, oneTimeCode: string): Promise<string | undefined> {
      const error = await this._login(() =>
        managementApi.user.loginWithOtc({ email: email.trim(), one_time_code: oneTimeCode.trim() })
      );

      // On being logged in (no error message), indicate that the logged-in user must change their
      // password before continuing.
      if (!error) {
        this.resettingPassword = true;
      }

      return error;
    },
    /** For internal use only. */
    async _login(loginCall: () => ApiPromise<UserLoginResponse>): Promise<string | undefined> {
      // Take the opportunity to reset all stores to avoid old state appearing during a different
      // login session.
      this._resetStores();

      // In the following, we return the same, coarse error message rather than a specific one
      // explaining why specifically the login attempt failed. To do otherwise would be a security
      // hole.

      const result = await loginCall();
      if (result.errored) {
        return t('errors.login-auth');
      }

      // Set the logged in user so that further API calls can authenticate against it.
      this.currentUser = result.data;

      // Use the most recently used workspace, which is returned by login API calls.
      if (!(await this.useWorkspace(result.data.workspace_uuid))) {
        return t('errors.login-auth');
      }

      // Request invites but don't wait for them as they're not critical for the login process to
      // complete. Reactive state will handle the consequence of loading this.
      const invitesStore = useInvitesStore();
      void invitesStore.findAll(true);

      return undefined;
    },
    async refreshAvailableWorkspaces(): Promise<void> {
      // Users are unlikely to have anywhere near this number of workspaces, so we assume this will
      // load all and not bother paginating.
      const MAX_WORKSPACES_PER_USER = 1000;

      this.availableWorkspaces =
        (
          await managementApi.user.workspaces.findAll({
            is_active: true,
            limit: MAX_WORKSPACES_PER_USER
          })
        ).data?.rows || [];
    },
    async useWorkspace(workspaceUuid: string): Promise<boolean> {
      if (!this.currentUser) {
        return false;
      }

      const workspace = (
        await managementApi.workspace.findOne(workspaceUuid, { withErrorMessageBox: false })
      ).data;
      if (!workspace) {
        return false;
      }

      this.currentWorkspace = workspace;

      // Signal that this workspace should be recognized as our most recently used.
      const workspaceUser = await managementApi.user.workspace.use(
        { user_uuid: this.currentUser.uuid, workspace_uuid: workspace.uuid },
        { withErrorMessageBox: false }
      );
      if (workspaceUser.errored) {
        return false;
      }

      // Update the role for this workspace now we've started to use it - role is per user & per
      // workspace.
      this.currentUser.role = workspaceUser.data.role;

      // Refresh the workspaces available to this user. This needs to be done after calling use so
      // that the workspaces have the correct last_used timestamps / ordering.
      await this.refreshAvailableWorkspaces();

      // Reset workspace-dependent stores
      this._resetStores(true);
      // Reset any currently selected configuration since they are defined per workspace - so we
      // don't want to remember this state from a previous workspace.
      this.currentConfiguration = undefined;

      return true;
    },
    async logout(callApi = true): Promise<void> {
      if (callApi) {
        await managementApi.user.logout();
      }

      this._resetStores();
    },
    async updateCurrentWorkspace(update: WorkspaceUpdateRequest) {
      const result = await managementApi.workspace.update(update);

      if (result.errored) {
        return false;
      }

      if (this.currentWorkspace) {
        this.currentWorkspace = { ...this.currentWorkspace, ...update };
      }

      return true;
    },
    async changeConfiguration(uuid: string, navigate: boolean) {
      // Set the indicated configuration in the store so it's visible everywhere in the sidebar
      // (this is why we do it this way rather than just doing a router push with the configuration
      // uuid as a parameter).
      const configurationsStore = useConfigurationsStore();
      const currentConfiguration = await configurationsStore.findOne(uuid);

      if (currentConfiguration) {
        const userStore = useUserStore();
        userStore.currentConfiguration = currentConfiguration;
      }

      if (navigate) {
        await useRouter().push({ name: ROUTE_NAME.CONFIGURATION });
      }
    },
    getChangePasswordRouteLocation(): RouteLocationRaw {
      return { name: ROUTE_NAME.CHANGE_PASSWORD, params: { userUuid: this.currentUser?.uuid } };
    },
    async navigateToChangePassword(): Promise<void> {
      await useRouter().push(this.getChangePasswordRouteLocation());
    },
    /** For internal use only. */
    _resetStores(onlyWorkspaceDependent = false) {
      // Conditionally reset all stores.
      const additionalStores = onlyWorkspaceDependent ? [] : [this];

      // Always reset stores containing transient per-workspace data.
      [
        ...additionalStores,
        useConfigurationsStore(),
        useInvitesStore(),
        useLoaderStore(),
        useSentimentsStore(),
        useTaxonomiesStore(),
        useTopicsStore(),
        useTopicSetsStore(),
        useUsersStore()
      ].forEach(s => s.$reset());
    }
  }
});
