import type { UseComponentBusReturn } from '@feeditback/fib-components';
import { useComponentBus } from '@feeditback/fib-components';
import { createGlobalState } from '@vueuse/core';

import type { SHARED_MODAL } from '../helpers/const';

type DetailComponent = { props: { uuid?: string; readonly?: boolean }; result: boolean };

type SharedModal = typeof SHARED_MODAL;

type Components = Record<
  | SharedModal['CONFIGURATIONS']
  | SharedModal['TOPICS']
  | SharedModal['TOPIC_SETS']
  | SharedModal['USERS']
  | SharedModal['TAXONOMIES']
  | SharedModal['SENTIMENTS'],
  DetailComponent
>;

type SharedModalsInstance = UseComponentBusReturn<Components>;

type UseSharedModalsReturn = {
  modals: SharedModalsInstance['components'];
  openModal: SharedModalsInstance['create'];
  closeModal: SharedModalsInstance['destroy'];
};

const useComponentBusGlobalState = createGlobalState(() => useComponentBus<Components>());

export const useSharedModals = (): UseSharedModalsReturn => {
  const { components, create, destroy } = useComponentBusGlobalState();
  return { modals: components, openModal: create, closeModal: destroy };
};
